<template>
  <div class="schedule-model">
    <el-dialog
    :title="title"
    :visible.sync="showScheduleForm"
    width="30%">
    <div class="dialog-content">
      <el-form ref="schedule-form" :model="form">
        <div class="row">
          <el-form-item label="Employee">
            <el-autocomplete
            class="inline-input addMemberInput"
            :fetch-suggestions="searchEmployee"
            v-model="form.name"
            placeholder="Input Employee Name"
            :trigger-on-focus="false"
            ></el-autocomplete>
          </el-form-item>
        </div>
        <div class="row">
          <el-form-item label="Date" class="field-date">
            <el-date-picker
            v-model="form.date"
            type="date"
            placeholder="Select Date">
          </el-date-picker>
        </el-form-item>
      </div>
        <div class="row">
          <el-form-item label="Start Time">
            <el-time-picker
              v-model="form.startTime"
              :format="'HH:mm'"
              :picker-options="{
                selectableRange: '00:00:00 - 23:59:59'
              }"
              placeholder="Start">
            </el-time-picker>
          </el-form-item>
        </div>
        <div class="row">
          <el-form-item label="End Time">
            <el-time-picker
              v-model="form.endTime"
              :format="'HH:mm'"
              :picker-options="{
                selectableRange: '00:00:00 - 23:59:59'
              }"
              placeholder="End">
            </el-time-picker>
          </el-form-item>
        </div>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="hiddenModal">Cancel</el-button>
        <el-button type="primary" v-if="!currentWorkshift" @click="createEvent">Add</el-button>
        <el-button type="primary" v-if="currentWorkshift" @click="updateEvent">Edit</el-button>
      </div>
    </div>
  </el-dialog>

 </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
import Common from '@/lib/common';
import Hr from '@/lib/hr';

export default {
  name: 'ScheduleForm',
  props:{
    eventName: String,
  },
  data(){
    //Default form value
    let form = {
      name: '',
      date: '',
      startTime: '',
      endTime: '',
    };

    return {
      form,
      title: 'Add Workshift Schedule',
    };
  },
  methods:{
    async updateEvent(){
      const employeeName = this.form.name;
      const employee = await this.getEmployeeByName(employeeName);
      const loginInfo = Common.getLoginInfo();

      const workshiftDay = dayjs(this.form.date).format('DD');
      const workshiftDate = dayjs(this.form.date).format('YYYY/MM/DD');

      //Find existed workshift of employee of that date
      const existedDay = this.currentWorkshifts.find(function(date){
        return date.day === workshiftDay;
      });

      if(existedDay){
        //If the update is same employee's workshift move to other time, it will be allowed; Otherwise will checking if workshift existed.
        if(employee.id != this.currentWorkshift.employee_id && this.currentWorkshift.date != dayjs(this.form.date).unix){
          const existedWorkshift = existedDay.workshifts.find(function(workshift){
            return workshift.employee_id === employee.id;
          });
          //If employee already had workshift on that date, update will not be allowed.
          if(existedWorkshift){
             this.$message.error(`This employee already had a workshift on ${workshiftDate}. You could not assign a workshift on that date.`);
             return;
          }
        }
      }

      const eventInfo = {
        employeeId: employee.id,
        date: dayjs(this.form.date).unix(),
        startTime: dayjs(this.form.startTime).unix(),
        endTime: dayjs(this.form.endTime).unix(),
      };

      try{
        const event = await Hr.updateWorkshift(this.apiUrl, this.currentWorkshift.id, eventInfo, loginInfo);
        this.hiddenModal();
        location.reload();
      }catch(err){
        console.log(err);
      }
    },
    async createEvent(){
      const employeeName = this.form.name;
      const employee = await this.getEmployeeByName(employeeName);
      const workshiftDay = dayjs(this.form.date).format('DD');
      const workshiftDate = dayjs(this.form.date).format('YYYY/MM/DD');

      const workshiftStartTime = dayjs(this.form.startTime);
      const workshiftEndTime = dayjs(this.form.endTime);

      if(workshiftStartTime.isSameOrAfter(workshiftEndTime)){
        this.$message.error(`The start time of workshift should not before then end time.`);
        return;
      }


      //Find existed workshift of employee of that date
      const existedDay = this.currentWorkshifts.find(function(date){
        return date.day === workshiftDay;
      });

      //If employee already had workshift on that date, update will not be allowed.
      if(existedDay){
        const existedWorkshift = existedDay.workshifts.find(function(workshift){
          return workshift.employee_id === employee.id;
        });
        if(existedWorkshift){
           this.$message.error(`This employee already had a workshift on ${workshiftDate}. You could not assign a workshift on that date.`);
           return;
        }
      }

      const loginInfo = Common.getLoginInfo();
      const eventInfo = {
        employeeId: employee.id,
        date: dayjs(this.form.date).unix(),
        startTime: dayjs(this.form.startTime).unix(),
        endTime: dayjs(this.form.endTime).unix(),
      };
      try{
        const event = await Hr.createWorkshift(this.apiUrl, eventInfo, loginInfo);
        this.hiddenModal();
        location.reload();
      }catch(err){
        console.log(err);
      }
    },
    async searchEmployee(queryString, cb){
      const loginInfo = Common.getLoginInfo();
      const employees = await Hr.loadAllEmployees(this.apiUrl, loginInfo);
      const employeeSuggest = [];

      for(let i = 0; i < employees.length; i++){
        const employee = employees[i];
        const employeeSelection = {
          'value': employee.employee_name,
          'name': employee.employee_name
        };
        employeeSuggest.push(employeeSelection);
      }

      const results = queryString ? employeeSuggest.filter(this.createEmployeeFilter(queryString)) : employees;

      cb(results);
    },
    async getEmployeeByName(memberName){
      const loginInfo = Common.getLoginInfo();
      const searchFields = {
        'employee_name': memberName,
      };
      const employees = await Hr.searchEmployee(this.apiUrl, searchFields, loginInfo);
      const employee = employees[0];
      return employee;
    },
    createEmployeeFilter(queryString) {
      return (employee) => {
        return (employee.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    hiddenModal(){
      this.removeCurrentWorkshift();
      this.hiddenScheduleForm();
    },
    ...mapActions(['displayScheduleForm', 'hiddenScheduleForm', 'removeCurrentWorkshift']),
  },
  watch: {
    'currentWorkshift': function(){
      if(this.currentWorkshift){
        this.title = 'Edit Workshift Schedule';
        this.form = {
          name: this.currentWorkshift.employee_name,
          date: dayjs.unix(this.currentWorkshift.date),
          startTime: dayjs.unix(this.currentWorkshift.start_time),
          endTime: dayjs.unix(this.currentWorkshift.end_time),
        };
      }
    }
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
    showScheduleForm: state => state.hr.showScheduleForm,
    currentWorkshift: state => state.hr.currentWorkshift,
    currentWorkshifts: state => state.hr.currentWorkshifts,
  }),
}
</script>

<style lang="scss">
.schedule-model{
  .row{
    padding-left: 20px;
  }
  .el-form-item{
    display: flex;
  }
  .el-dialog__close{
    display: none;
  }
}


</style>
